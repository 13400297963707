import './../../bootstrap'
import login from './../../components/auth/login.vue'

/* eslint-disable no-unused-vars */
const app = new Vue({
  el: '#app',
  components: {
    login
  }
})
